import { h } from 'preact';

import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

// Pages - Code-splitting is automated for `routes` directory
import Home, { pathName as rootPath } from '../routes/Home/Home';
import DownloadPlugin from '../routes/DownloadPlugin/DownloadPlugin';

import useDatadog from '../common/hooks/use-datadog';
import loadDependencies from '../dependency-injection/load-dependencies';

import store from '../redux/store';

export const pathName = '/plugin';

const ShortLinkHome = () => {
    const { gameUid } = useParams();

    return <Home gameUid={gameUid} />;
};

const App = () => {
    useDatadog();
    loadDependencies();

    return (
        <ReduxProvider store={store}>
            <Router>
                <Routes>
                    <Route path={rootPath} element={<Home />} />
                    <Route path={pathName} element={<DownloadPlugin />} />
                    <Route path="/:gameUid" element={<ShortLinkHome />} />
                </Routes>
            </Router>
        </ReduxProvider>
    );
};

export default App;

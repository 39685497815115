import { Dispatch, StateUpdater, useCallback, useContext, useEffect, useMemo, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import {
    FEATURE_PROGRESS,
    IShowoffItemFormatted,
    SHOWOFF_ELEMENTS,
    StepType,
    IContextShowoff
} from './helpers/showoff.types';
import { GameWindowContext } from '../../../components/GameWindow/GameWindow';
import { isRunningInsideWrapper } from '../../wrapper-bridge-mobile';
import structure from './helpers/structure';
import createTree from './helpers/helpers';
import { useCurrentSelected } from './helpers/useCurrentSelected';
import { dashboardItemsSelector } from '../../../selectors/game-window/content';
import { useLogTracking } from '../use-user-tracking/useUserTracking';
import { getAmplitudeKey } from '../use-user-tracking/types';

/*
    Rules defined for feature saving in localStorage:
     - naming however you want
     - NO using tilde (used for filtering like "feature_name~full" if the features went full flow or just noticed)
 */
const useShowoff = () => {
    const { logTrackingComponent } = useLogTracking();
    const isWrapper = isRunningInsideWrapper();
    const setStorage = useCallback(
        (key: string, value: string) => {
            if (isWrapper) {
                return window?.['AndroidWrapper']?.['savePreference'](key, value);
            }
            return localStorage.setItem(key, value);
        },
        [isWrapper]
    );
    const getStorage = useCallback(
        (key: string) => {
            try {
                if (isWrapper) {
                    return window?.['AndroidWrapper']?.['getPreference'](key);
                }

                return localStorage.getItem(key);
            } catch (e) {
                return 'GETTER_NOT_ACCESSIBLE';
            }
        },
        [isWrapper]
    );

    const showedOff = getStorage('showoff');

    const showedOffArray = useMemo(() => showedOff?.split(' ') ?? [], [showedOff]);
    const gameLoaded = useSelector(state => state?.['gameWindow']?.gameLoaded);
    const displayMenu = useSelector(state => state?.['displayMenu']?.displayMenu);
    const dashboardItems = useSelector(dashboardItemsSelector);
    const { setShowoffParams } = useContext(GameWindowContext) as {
        setShowoffParams: Dispatch<StateUpdater<IContextShowoff & { slideTo: (index: number) => void }>>;
    };

    const [initialized, setInitialized] = useState<boolean>(false);
    const [toShow, setToShow] = useState<IShowoffItemFormatted>(null);
    const {
        waitForDismiss,
        setWaitForDismiss,
        applyNotificationsAndBadges,
        currentSelected,
        setCurrentSelected,
        checkBypassOnNext,
        inView
    } = useCurrentSelected({ toShow, setToShow });

    // initial list to be made for the features
    const pendingShow = useMemo(() => {
        if (!gameLoaded || showedOff === 'GETTER_NOT_ACCESSIBLE') {
            return null;
        }

        const steps: StepType[] = [];
        const toShow: IShowoffItemFormatted[] = [];
        const highestOrder = { order: 1 };

        const storedFeatures = showedOffArray?.reduce(
            (acc, val) => {
                const [feature, flow] = val.split('~');
                if (feature && flow) {
                    return { ...acc, [flow]: [...acc[flow], feature] };
                }
                return acc;
            },
            { [FEATURE_PROGRESS.FULL]: [], [FEATURE_PROGRESS.NOTICED]: [] }
        );

        const featuresMap = new Map([
            [FEATURE_PROGRESS.FULL, storedFeatures.full],
            [FEATURE_PROGRESS.NOTICED, storedFeatures.noticed]
        ]);

        createTree(structure, featuresMap, toShow, steps, highestOrder, dashboardItems);

        let maxIndex = 0;
        let maxOrder = 0;

        toShow.forEach((item, index) => {
            if (item.order > maxOrder) {
                maxOrder = item.order;
                maxIndex = index;
            }
        });

        if (maxOrder < highestOrder.order) {
            return null;
        }

        return [toShow[maxIndex]];
    }, [dashboardItems, gameLoaded, showedOff, showedOffArray]);

    // `${feature-name}~noticed` going in local storage, marking user pressed on X
    const markNoticed = useCallback(() => {
        setToShow(prev => {
            const prevStorage = showedOff && showedOff.length > 0 ? `${showedOff} ` : '';
            setStorage('showoff', `${prevStorage}${prev.name}~${FEATURE_PROGRESS.NOTICED}`);

            return null;
        });
        setInitialized(false);
    }, [setStorage, showedOff]);

    // `${feature_name}~full` going in local storage, marking user viewed the feature and closed the menu
    const markFull = useCallback(() => {
        setToShow(prev => {
            let prevStorage = showedOff && showedOff.length > 0 ? `${showedOff} ` : '';

            if (prevStorage.length > 0) {
                const features = prevStorage.split(' ');

                prevStorage = features.filter(item => !item.includes(prev?.name)).join(' ');
            }

            setStorage('showoff', `${prevStorage}${prev?.name}~${FEATURE_PROGRESS.FULL}`);

            setShowoffParams(prev => ({ ...prev, feature: null, currentStep: null }));
            return null;
        });
        setCurrentSelected(null);
    }, [setCurrentSelected, setShowoffParams, setStorage, showedOff]);

    useEffect(() => {
        if (waitForDismiss && toShow && !inView) {
            applyNotificationsAndBadges(true);
            setWaitForDismiss(false);
            markFull();
        }
    }, [applyNotificationsAndBadges, displayMenu, inView, markFull, setWaitForDismiss, toShow, waitForDismiss]);

    useEffect(() => {
        if (!initialized && pendingShow) {
            if (pendingShow[0].show) {
                logTrackingComponent({
                    [getAmplitudeKey('EVENT')]: 'In Game Notification Viewed',
                    [getAmplitudeKey('MODULE')]: 'feature_notification',
                    [getAmplitudeKey('ZONE_NAME')]: undefined
                });
            }
            setInitialized(true);
            setShowoffParams(prev => {
                return { ...prev, feature: pendingShow[0], name: pendingShow[0].name };
            });
            setToShow(() => {
                const feature = pendingShow[0];

                if (feature.steps && feature.steps.length > 0) {
                    const remain = feature.steps.filter(item => !checkBypassOnNext(item));

                    return { ...feature, steps: remain };
                }
            });
        }
    }, [checkBypassOnNext, logTrackingComponent, initialized, pendingShow, setShowoffParams]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            applyNotificationsAndBadges();
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [applyNotificationsAndBadges, displayMenu]);

    return {
        elemRef: currentSelected,
        action: () => {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'In Game Notification Selected',
                [getAmplitudeKey('MODULE')]: 'feature_notification',
                [getAmplitudeKey('ZONE_NAME')]: undefined
            });
            currentSelected.element.click();
        },
        actionDismiss: () => {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'In Game Notification Closed',
                [getAmplitudeKey('MODULE')]: 'feature_notification',
                [getAmplitudeKey('ZONE_NAME')]: undefined
            });
            markNoticed();
        }
    };
};

export { useShowoff, SHOWOFF_ELEMENTS };

export const FetchType = Object.freeze({
    Unknown: 'Unknown',
    License: 'License',
    Location: 'Location',
    SeamlessLogin: 'SeamlessLogin',
    UserSession: 'UserSession',
    PLCConnect: 'PLCConnect',
    ConnectionInfo: 'ConnectionInfo',
    TrendingGames: 'TrendingGames',
    CustomerPackage: 'CustomerPackage',
    CustomerFeatures: 'CustomerFeatures',
    UserContext: 'UserContext',
    GameWindow: 'GameWindow',
    GetRecentlyPlayed: 'GetRecentlyPlayed',
    AddRecentlyPlayed: 'AddRecentlyPlayed',
    ServerTime: 'ServerTime'
});

export const getRequestUid = name => {
    //return memo({ name, key: Symbol(name) }, (prev, next) => prev.name === next.name)
    // return { name, key: Symbol(name) };
    return { name, key: name };
};

export const remove = (items, key) => {
    return items.filter(ele => {
        return ele.key !== key;
    });
};

export const userOS = {
    WINDOWS: 'win',
    MAC: 'mac',
    ANDROID: 'android'
};

export const AW_COOKIE = {
    AUTH_TOKEN: 'X-Auth-Token',
    LOGIN_TOKEN: 'X-Login-Token',
    CURRENCY: 'X-Currency',
    REGION: 'X-Region',
    SESSION_ID: 'Session-ID',
    USER_ID: 'User-ID'
};

export const WRAPPER_COOKIES = {
    IS_QUICK_LAUNCH: 'x-is-quick-launch',
    IS_USING_NEW_QUICK_LAUNCH: 'x-is-using-new-quicklaunch',
    WRAPPER_APP_CONFIG: 'wrapperAppConfig',
    ANDROID_APP_VERSION: 'X-Android'
};

export const COOKIES = {
    WRAPPER: 'wrapper',
    X_SHOW_ALL_REGIONS_TOKEN: 'x-show-all-regions-token'
};

export const AMPLITUDE_COOKIES = {
    AMPLITUDE_ID: 'amplitude-id',
    AMP_SESSION_ID: 'amp_session_id',
    AMP_DEVICE_ID: 'amp_device_id'
};

export const HEADERS = {
    CACHE_CONTROL: 'Cache-Control',
    X_PRODUCT_REGION: 'X-Product-Region'
};

export const GAN_COOKIE = {
    API_TOKEN: 'ganApiToken',
    PLAYER_TOKEN: 'ganPlayerUid'
};

export const SESSION_COOKIE = {
    START_TIME: 'userSessionStartTime',
    STATE_TIME: 'currentStateTime'
};

export const PX_COOKIE = {
    PX_AUTHORIZATION_TOKEN: '_px3'
};

export const DEVICE_TYPE = {
    PHONE: 'PHONE',
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    UNKNOWN: 'UNKNOWN'
};

export const MESSAGES = {
    RELOAD: 'RELOAD_REQUIRED',
    CLOSE_REALITY_CHECK_MODAL: 'CLOSE_REALITY_CHECK_MODAL',
    VERIFY_REALITY_CHECK: 'VERIFY_REALITY_CHECK',
    GAME_LAUNCHER_CLOSE: 'GAME_LAUNCHER_CLOSE',
    HIDE_HEADER: 'HIDE_HEADER',
    INCENTIVE_GAME_UPDATE: 'INCENTIVE_GAME_UPDATE',
    INCENTIVE_GAME_REDIRECT: 'INCENTIVE_GAME_REDIRECT',
    TRACKING: 'TRACKING',
    GAME_LAUNCHER_EXIT_BUTTON: 'APP_IN_BACKGROUND',
    REQUEST_GAME_CLOSE: 'REQUEST_GAME_CLOSE',
    SHOW_DEPOSIT: 'SHOW_DEPOSIT',
    LOGIN_REDIRECT: 'LOGIN_REDIRECT',
    OPEN_EXTERNAL_WEBVIEW: 'OPEN_EXTERNAL_WEBVIEW',
    REFRESH_RECENTLY_PLAYED: 'REFRESH_RECENTLY_PLAYED',
    RELOAD_GAME: 'RELOAD_GAME',
    SHOW_HEADER: 'SHOW_HEADER',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    TOKEN_REFRESH: 'TOKEN_REFRESH'
};

export const OOBEE_URLS = {
    NJ: {
        oobeeUrl: 'https://nj-stg-oobee.geocomply.net/',
        serviceUrl: 'https://stg-es.geocomply.net/oobee.php'
    },
    US2: {
        oobeeUrl: 'https://nv-stg-oobee.geocomply.net/',
        serviceUrl: 'https://nv-stg-es.geocomply.net/enh-engine.php'
    },
    US3: {
        oobeeUrl: 'https://us3-stg-oobee.geocomply.net/',
        serviceUrl: 'https://us3-stg-es.geocomply.net/oobee.php'
    },
    US5: {
        oobeeUrl: 'https://us5-stg-oobee.geocomply.net/',
        serviceUrl: 'https://us5-stg-es.geocomply.net/oobee.php'
    },
    US6: {
        oobeeUrl: 'https://us6-stg-oobee.geocomply.net/',
        serviceUrl: 'https://us6-stg-es.geocomply.net/oobee.php'
    },
    US7: {
        oobeeUrl: 'https://us7-stg-oobee.geocomply.net/',
        serviceUrl: 'https://us7-stg-es.geocomply.net/oobee.php'
    }
};

export const PRODUCTS = {
    CASINO: 'casino',
    XSELL: 'xsell'
};

export const TIMERS_TIMESTAMPS = {
    GAME_MARK: 'GAME_MARK',
    SERVER_MARK: 'SERVER_MARK',
    GAME_MEASURE: 'CURRENT_GAME_MEASURE',
    CURRENT_MEASURE: 'CURRENT_MEASURE',
    SESSION_MEASURE: 'SESSION_MEASURE'
};

export const GEOCOMPLY_ERROR_TYPES = {
    GENERAL: 'GENERAL',
    VALIDATION: 'VALIDATION'
};

export const AGGREGATION_HEADERS = {
    X_REGION_CODE: 'X-Region-Code',
    X_BRAND: 'X-Brand'
};

export const RGIS = {
    GAN: 'gan',
    INCENTIVE_GAMES: 'incentive_games',
    FGP: 'fgp'
};

export const LOCAL_STORAGE_KEYS = {
    REDUX_STORE_LICENSE_KEY: 'REDUX_STORE_LICENSE_KEY',
    REDUX_STORE_LOCATION_KEY: 'REDUX_STORE_LOCATION_KEY'
};

export const GEOCOMPLY_COOKIES = {
    IP_ADDRESS: 'geocomply_ip_address',
    XSELL_GEOCOMPLY_TOKEN: 'XsellGeocomplyToken',
    XSELL_GEOCOMPLY_TOKEN_UPDATED: 'XsellGeocomplyTokenUpdated'
};

export const GAME_INFO_CARD_TYPES = {
    EXTERNAL_LINK: 'external_link',
    HOW_TO_PLAY: 'how_to_play',
    PAYTABLE: 'paytable'
};

export const RM_USER_ELIGIBILITY_ERRORS = {
    USER_NOT_ELIGIBLE: 'Verify your account to play Reward Machine!',
    GENERAL_ERROR: 'Something went wrong. Try again!'
};

export const RECENTLY_GAMES_OPERATION = {
    ADD: 'add',
    GET: 'read'
};

export const THEMES = {
    DEFAULT: 'default',
    CHRISTMAS: 'christmas',
    NFL: 'nfl'
};

export const GAME_CONTEXT = {
    OVERALL_STATUS: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        ACTIVE_BUT_NOT_DISPLAYED: 'Active but not displayed',
        ACTIVE_BUT_BETTING_DISABLED: 'Active but betting disabled'
    }
};

export const ADD_FUNDS_ICON_SIZES = {
    HEADER: 28,
    FOOTER: 34
};

export const TIME_CONSTANTS = {
    MILLISECOND: 1,
    SECOND: 1000,
    MINUTE: 60 * 1000,
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 30 * 24 * 60 * 60 * 1000, // Approximation, actual month duration can vary
    YEAR: 365 * 24 * 60 * 60 * 1000 // Approximation, actual year duration can vary
};

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'preact/hooks'
import dayjs from 'dayjs'

import { getRealityCheck, verifyRealityCheck } from '../../../redux/actions/realityCheck'
import { types } from '../../../redux/types'

import { isRunningInsideWrapper, sendWrapperEvent } from '../../../common/wrapper-bridge-mobile'
import { substituteJsonPlaceholders } from '../../../common/helpers/jsonPlaceholders'
import { getRegion } from '../../../common/helpers/cookies'
import { MESSAGES } from '../../../common/constants'

import config from '../../../../config/config'
import { hidePopup, showPopup } from '../../../redux/reducers/popup/popup'
import { isMobile } from 'react-device-detect'


export const timeToString = time => {
    return time === 1 ? 'minute' : 'minutes'
}

export const getFormattedAmount = amount => {
    if (isNaN(amount)) {
        return 0
    }
    return parseFloat(amount.toFixed(2))
}

export const closeRealityCheckPopup = () => async dispatch => {
    dispatch({ type: types.REALITY_CHECK_HIDE })
    dispatch(hidePopup());

    if (isRunningInsideWrapper()) {
        sendWrapperEvent(MESSAGES.CLOSE_REALITY_CHECK_MODAL, { transferList: '*' })
    } else {
        window?.opener?.postMessage?.({ type: MESSAGES.CLOSE_REALITY_CHECK_MODAL }, config.casinoWebUrl)
    }
}

export const redirectRealityCheckUrl = () => {
    const region = getRegion().toUpperCase()
    const realityCheckUrl = substituteJsonPlaceholders(config.realityCheck, { region })
    if (isMobile) {
        window.location = realityCheckUrl
    } else {
        window.open(realityCheckUrl, '_blank');
    }
}

//eslint-disable-next-line sonarjs/cognitive-complexity
const RealityCheckWeb = () => {
    const { t } = useTranslation()
    const displayModal = useSelector(state => state?.realityCheck?.displayModal)
    const time = useSelector(state => state?.realityCheck?.notificationParams?.playedTime)
    const amount = useSelector(state => state?.realityCheck?.notificationParams?.profitAndLoss)
    const userId = useSelector(state => state?.session?.userId)
    const realityCheckInterval = useSelector(state => state?.realityCheck?.notificationParams?.interval)
    const realityCheckCurrentTimer = useSelector(state => state?.realityCheck?.notificationParams?.currentTimer ?? 0)
    const shouldVerifyRealityCheck = useSelector(state => state?.realityCheck?.shouldVerifyRealityCheck)

    const dispatch = useDispatch()
    const timeUnits = timeToString(time)
    const timer = (realityCheckInterval * 60 - realityCheckCurrentTimer) * 1000

    const formattedAmount = getFormattedAmount(amount)

    const [timeout, setTimeoutValue] = useState(null)
    const [timeOfLastCheck, setTimeOfLastCheck] = useState(null)
    const handleRealityCheck = async () => {
        await dispatch(getRealityCheck(userId))
            .then(() => {
                dispatch({ type: types.REALITY_CHECK_DISPLAY })
            })
            .catch(error => {
                // Safety measure to hide any modal in case of an error related to reality check, remove if not needed at some point
                dispatch({ type: types.REALITY_CHECK_HIDE })
                console.warn('Reality Check error: ', error)
            })
    }

    const realityCheckTimeout = timer => {
        setTimeOfLastCheck(dayjs())

        timeout && clearTimeout(timeout)
        const timeoutValue = setTimeout(async () => {
            await handleRealityCheck()
        }, timer)

        setTimeoutValue(timeoutValue)
    }

    useEffect(() => {
        if (userId) {
            dispatch(getRealityCheck(userId))
        }

    }, [userId, dispatch])

    useEffect(() => {
        if (timer) {
            realityCheckTimeout(timer)
        }
    }, [userId, timer])

    useEffect(() => {
        if (shouldVerifyRealityCheck) {
            (async function () {
                const now = dayjs()
                const timePassed = now.diff(timeOfLastCheck, 'ms')

                if (timePassed < timer) {
                    dispatch(getRealityCheck(userId))
                } else {
                    await handleRealityCheck()
                }
                dispatch(verifyRealityCheck(false))
            })()
        }
    }, [shouldVerifyRealityCheck])

    useEffect(() => {
        if (displayModal) {
            dispatch(showPopup({
                icon: true,
                title: t('realityCheck.title'),
                description: <div>
                    <p style="margin: 0;">
                        {t('realityCheck.wagered1')} <b>${formattedAmount}</b> {t('realityCheck.wagered2')}
                        <b>
                            {time} {t(`global.${timeUnits}`)}
                        </b>
                        .
                    </p>
                    <p style="margin: 0;">{t('realityCheck.note')}</p>
                </div>,
                buttons: {
                    dismissAction: {
                        title: t('realityCheck.gotIt'),
                        handler: 'DISMISS_REALITY_CHECK',
                    },
                    primaryAction: {
                        title: t('realityCheck.edit'),
                        handler: 'REDIRECT_REALITY_CHECK_URL'
                    }
                }
            }))
        }
    }, [displayModal])
}

export default RealityCheckWeb

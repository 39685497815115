import config from '../../../../config/config';

import { AW_COOKIE, MESSAGES } from '../../constants';

import { getAWCookies, removeAWCookies, setAWCookies } from '../../helpers/cookies';
import { loginRedirect } from '../../helpers/loginRedirect';
import {
    sendIosSessionExpiredMessage,
    isRunningInsideV3Wrapper,
    isRunningInsideWrapper,
    sendWrapperEvent,
    sendIosTokenRefreshMessage
} from '../../wrapper-bridge-mobile';

export default function sessionExpirationRequestInterceptor(axiosInstance) {
    const interceptor = axiosInstance.interceptors.response.use(
        requestResponse => requestResponse,
        requestError => {
            console.log('Session expired intercepted')
            const status = requestError?.response?.status;
            if (status !== 401) {
                return Promise.reject(requestError);
            }

            axiosInstance.interceptors.response.eject(interceptor);

            const { loginToken } = getAWCookies() || {};

            return axiosInstance
                .post(
                    config.accountAndWalletApi.endpoints.SESSION_RENEW,
                    {
                        login_token: loginToken
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${config.clientID}`
                        }
                    }
                )
                .then(response => {
                    const { id: authTokenResponse, login_token: loginTokenResponse } =
                        response?.data?.sessions[0] || {};
                    setAWCookies(authTokenResponse, loginTokenResponse);
                    if (response?.status === 401){
                        sendIosSessionExpiredMessage();
                    }

                    axiosInstance.defaults.headers[AW_COOKIE.AUTH_TOKEN] = authTokenResponse;
                    requestError.response.config.headers[AW_COOKIE.AUTH_TOKEN] = authTokenResponse;

                    sendIosTokenRefreshMessage({
                        authToken: authTokenResponse,
                        loginToken: loginTokenResponse
                    });

                    return axiosInstance(requestError.response.config);
                })
                .catch(() => {
                    console.log('ERROR Renewing session')
                    if (isRunningInsideWrapper() || isRunningInsideV3Wrapper()) {
                        removeAWCookies();
                        sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
                        sendIosSessionExpiredMessage();
                    } else {
                        loginRedirect(window.location.search);
                    }
                })
                .finally(() => {
                    // Re-inject the response interceptor
                    sessionExpirationRequestInterceptor(axiosInstance);
                });
        }
    );
}

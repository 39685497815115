import { JackpotInformation } from './JackpotInformation';

export const JackpotTier = ({ jackpotTier, tierLogo, userOptedIn, SPServiceActive, jackpotIdMatch }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    return (
        <div className="jackpot-tier">
            <img className="swj-logo" src={tierLogo} />

            <div className="swj-jackpot-wrapper">
                <div className="swj-jackpot">
                    <div className="swj-jackpot-type">{jackpotTier?.name?.toUpperCase?.()}</div>
                    <JackpotInformation
                        userOptedIn={userOptedIn}
                        jackpotWonTimes={jackpotTier?.numberOfWins}
                        jackpotAmount={jackpotTier?.currentAmount}
                        SPServiceActive={SPServiceActive}
                        jackpotIdMatch={jackpotIdMatch}
                    />
                </div>
            </div>
        </div>
    );
}

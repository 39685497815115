import { useCallback } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { sendDepositMessage } from '../../../common/helpers/send-deposit-message';
import { useOrientation } from '../../../common/hooks/useOrientation';
import { ERROR_CTA_EVENTS } from '../../../game-window/contants/errors';
import { depositLinkSelector, inGameErrorSelector } from '../../../selectors/game-window';
import { AMPLITUDE_ERROR_EVENTS, MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';

export const GenericDepositButton = ({ handleDismiss, buttonMessage, buttonStyle }) => {
    const depositLink = useSelector(depositLinkSelector);
    const { deviceType } = useOrientation();
    const { errorType } = useSelector(inGameErrorSelector);
    const { logTrackingComponent } = useLogTracking();

    const handleDepositButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: errorType,
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS.ADD_FUNDS,
            [getAmplitudeKey('LINK_URL')]: depositLink
        });
        sendDepositMessage(depositLink);
        handleDismiss();
    }, [errorType, logTrackingComponent, handleDismiss, depositLink]);

    return (
        <>
            {depositLink && (
                <button className={`deposit-button ${deviceType} ${buttonStyle}`}>
                    <a href={depositLink} rel="noreferrer" target="_blank" onClick={handleDepositButton}>
                        {buttonMessage}
                    </a>
                </button>
            )}
        </>
    );
};

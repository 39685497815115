import { useEffect, useState } from 'preact/hooks';
import { WinFrequency, WinnersFeed } from '@fanduel/casino-swj-consumer';
import { getFgpEndpoint } from '../../../../../../common/helpers/get-fgp-endpoint';
import { getRegion } from '../../../../../../common/helpers/cookies';
import { datadogLogs } from '@datadog/browser-logs';

export const useSocialProofing = (jackpotId) => {
    const [winnersFeed, setWinnersFeed] = useState(null);
    const [winFrequency, setWinFrequency] = useState(null);

    const winnersFeedInstance = new WinnersFeed({
        host: getFgpEndpoint(),
        region: getRegion(),
        jackpotIds: [jackpotId],
        numberOfUsers: 10,
        callback: setWinnersFeed, // response contains data[0].['latestWins'], the message should be crafted from that information
        error: error => {
            // you can use this with a callback in the parent to disable displaying the winners feed
            datadogLogs.logger.error('[MDS][FEED]jackpot_winners_fetch_failed',
                Object.assign(
                    {
                        type: '[MDS][FEED]jackpot_winners_fetch_failed',
                    },
                    jackpotId,
                    error
                )
            );
            console.error(error);
        }
    })

    const winFrequencyInstance = new WinFrequency({
        host: getFgpEndpoint(),
        region: getRegion(),
        timeInterval: 'last24h',
        jackpotIds: [jackpotId],
        callback: setWinFrequency, // response contains data[0].['tiersNumberOfWins'], the message should be matched from that information
        error: (error) => {
            // you can use this with a callback in the parent to disable displaying the win frequency
            datadogLogs.logger.error('[MDS][FEED]jackpots_win_frequency_fetch_failed',
                Object.assign(
                    {
                        type: '[MDS][FEED]jackpots_win_frequency_fetch_failed',
                    },
                    jackpotId,
                    error
                )
            );
            console.log(error);
        }
    });

    useEffect(() => {
        winnersFeedInstance.connect();
        winFrequencyInstance.connect();

        return () => {
            winnersFeedInstance.disconnect();
            winFrequencyInstance.disconnect();
        };
    }, [])

    return { winnersFeed, winFrequency };
}

import { createSelector } from '@reduxjs/toolkit';
import { RGIS } from '../../common/constants';

export const gameContextSelector = state => state.gameContext;

export const rgiSelector = createSelector(gameContextSelector, ctx => {
    if (typeof ctx?.rgi === 'undefined') {
        return undefined;
    }

    const rgi = ctx?.rgi?.toLowerCase();
    return Object.values(RGIS).includes(rgi) ? rgi : RGIS.GAN;
});
export const brandSelector = createSelector(gameContextSelector, ctx => ctx?.brand);
export const gameNameSelector = createSelector(gameContextSelector, ctx => ctx?.gameName);
export const gameIdSelector = createSelector(gameContextSelector, ctx => ctx?.gameId);
export const providerSelector = createSelector(gameContextSelector, ctx => ctx?.provider);
export const providerRefSelector = createSelector(gameContextSelector, ctx => ctx?.providerRef);
export const ganIdSelector = createSelector(gameContextSelector, ctx => ctx?.ganId);
export const regionSelector = createSelector(gameContextSelector, ctx => ctx?.region);
export const overallStatusSelector = createSelector(gameContextSelector, ctx => ctx?.overallStatus);
export const gameUidSelector = createSelector(gameContextSelector, ctx => ctx?.gameUid);
export const gameLoadedSelector = createSelector(gameContextSelector, ctx => ctx?.gameLoaded);
export const nativeIdSelector = createSelector(gameContextSelector, ctx => ctx?.nativeId);
export const directLaunchParamsSelector = createSelector(gameContextSelector, ctx => ctx.directLaunchParams);

import { Fragment, JSX } from 'preact';
import React from 'react';
import { PositionType } from '../helpers/showoff.types';

type OverlayProps = {
    position: PositionType;
    style: JSX.CSSProperties;

};

type CommonOverlayProps = {
    offsetTop: number;
    offsetLeft: number;
    height?: number;
    width: number;
}

export const Overlay = ({ position, style }: OverlayProps) => {
    return <div className={`showoff-overlay ${position}`} style={style as any} />;
};

export const CommonOverlay = ({ offsetTop, offsetLeft, height, width }: CommonOverlayProps) => {
    return (
        <>
            <Overlay
                position={'top'}
                style={{
                    height: offsetTop >= 0 ? `${offsetTop}px` : ''
                }}
            />
            <Overlay
                position={'right'}
                style={{
                    top: offsetTop >= 0 ? `${offsetTop}px` : '',
                    height: height ? `${height}px` : '',
                    left: offsetLeft >= 0 && width ? `${offsetLeft + width}px` : ''
                }}
            />
            <Overlay
                position={'bottom'}
                style={{
                    top: offsetTop >= 0 && height ? `${offsetTop + height}px` : ''
                }}
            />
            <Overlay
                position={'left'}
                style={{
                    top: offsetTop >= 0 ? `${offsetTop}px` : '',
                    height: height ? `${height}px` : '',
                    width: offsetLeft >= 0 ? `${offsetLeft}px` : ''
                }}
            />
        </>
    );
};


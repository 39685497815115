import { Dispatch, StateUpdater, useCallback, useContext, useEffect, useState } from 'preact/hooks';
import {
    CurrentSelectedType,
    DISMISS_TYPE,
    FOCUS_TYPE,
    IContextShowoff,
    IShowoffItemFormatted,
    StepType
} from './showoff.types';
import { GameWindowContext } from '../../../../components/GameWindow/GameWindow';
import { useInView } from 'react-intersection-observer';

interface IUseCurrentSelected {
    toShow: IShowoffItemFormatted;
    setToShow: Dispatch<StateUpdater<IShowoffItemFormatted>>;
}

export const useCurrentSelected = ({ toShow, setToShow }: IUseCurrentSelected) => {
    const [waitForDismiss, setWaitForDismiss] = useState(false);
    const [currentSelected, setCurrentSelected] = useState<CurrentSelectedType>();
    const { ref, inView } = useInView({
        onChange: inView => {
            if (toShow && toShow.steps.length > 0 && toShow.notice && inView) {
                setWaitForDismiss(true);
            }
        }
    });

    const { showoffParams, setShowoffParams } = useContext(GameWindowContext) as {
        showoffParams: IContextShowoff & { slideTo: (index: number) => void };
        setShowoffParams: Dispatch<StateUpdater<IContextShowoff & { slideTo: (index: number) => void }>>;
    };

    // bypassing a step if the element is already focused in the UI
    const checkBypassOnNext = (step: StepType) => {
        const elem: HTMLElement = document.querySelector(`[data-showoff=${step.attr}]`);

        return step?.bypassCondition && step.bypassCondition(elem);
    };

    const applyNotificationsAndBadges = useCallback(
        (revoke = false) => {
            const notificationType = {
                BADGE: 'data-showoff-badge',
                NOTIFICATION: 'data-showoff-notification'
            };

            const steps = revoke ? showoffParams?.feature?.steps : toShow?.steps;

            steps?.forEach((step, index) => {
                const element = document.querySelector(`[data-showoff="${step.attr}"]`);
                const type = element?.getAttributeNames().find(attr => Object.values(notificationType).includes(attr));

                if (!(index === 0 && step.focusType !== FOCUS_TYPE.NOTHING) || toShow.notice) {
                    element?.setAttribute(type, revoke ? 'false' : 'true');
                }
            });
        },
        [toShow]
    );

    // going to the next step on the feature showoff
    const invokeNextStep = useCallback(() => {
        setToShow(prev => {
            if (prev.steps && prev.steps.length > 0) {
                const [, ...remainSteps] = prev.steps;

                const remain = remainSteps.filter(item => !checkBypassOnNext(item));

                return { ...prev, steps: remain };
            }

            return null;
        });
    }, [setToShow]);

    // check it out button (usually for clickable elements for going to the next step)
    const popupAction = useCallback(() => {
        setTimeout(() => {
            invokeNextStep();
        }, 200);
    }, [invokeNextStep]);

    useEffect(() => {
        if (toShow && showoffParams['dashboardItems']) {
            const dashItem = showoffParams['dashboardItems'].find(item => item.type === toShow.name);

            ref(dashItem.ref.current);
        }
    }, [toShow, currentSelected, showoffParams, ref]);

    useEffect(() => {
        // selecting the next element from the steps
        if (toShow && toShow.steps.length > 0 && toShow.show) {
            setCurrentSelected(prev => {
                if (prev?.element) {
                    prev.element.style.zIndex = '2';
                }

                const current = toShow.steps[0];

                const currentElement: CurrentSelectedType = {
                    element: document.querySelector(`[data-showoff="${current.attr}"]`) as HTMLElement,
                    focusType: current.focusType,
                    bypassCondition: current.bypassCondition,
                    dismissType: current.dismissType
                };

                setShowoffParams(prev => ({ ...prev, currentStep: { ...currentElement, name: current.attr } }));

                return currentElement;
            });
        } else if (!waitForDismiss) {
            setCurrentSelected(null);
        }
    }, [setCurrentSelected, setShowoffParams, toShow, waitForDismiss]);

    useEffect(() => {
        if (currentSelected?.element) {
            currentSelected.element.style.zIndex = '5';
            if (currentSelected.dismissType === DISMISS_TYPE.ACTION) {
                currentSelected?.element.addEventListener('click', popupAction);
            } else {
                // swipe to card on Check it out click
                const position = showoffParams?.['dashboardItems'].findIndex(item => toShow?.name.includes(item.type));
                setTimeout(() => {
                    showoffParams?.['swiperInstance']?.slideTo && showoffParams?.['swiperInstance']?.slideTo(position);
                }, 500);
                setWaitForDismiss(true);
            }
        }

        return () => {
            currentSelected?.element?.removeEventListener('click', popupAction);
        };
    }, [currentSelected, invokeNextStep, popupAction, showoffParams, showoffParams.slideTo, toShow]);

    return {
        waitForDismiss,
        setWaitForDismiss,
        applyNotificationsAndBadges,
        currentSelected,
        setCurrentSelected,
        checkBypassOnNext,
        inView
    };
};

import { Action } from '../../redux/reducers/popup/types';
import { popupActions } from '../../redux/actions/popup/index';
import { useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { useOrientation } from '../../common/hooks/useOrientation';
import { AnyAction } from '@reduxjs/toolkit';

type Props = {
    type: 'primary' | 'dismiss';
    action: Action;
};

export const ActionButton = ({ action }: Props) => {
    const handlerFunc = popupActions[action.handler];
    const dispatch = useDispatch();

    const { deviceType } = useOrientation();

    const actionHandler = useCallback(() => dispatch(handlerFunc(action?.handlerParams) as AnyAction), [dispatch, handlerFunc]);

    return <button className={deviceType} onClick={actionHandler}>{action.title}</button>;
};
